import React, { useEffect } from 'react';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const HandleJob = ({ setJobId, setSpycareJobId, setPortal, setRecAccess, setInvAccess, setIbaAccess }) => {
  const { jobId } = useParams();
  const { token, user } = useAuth();
  const search = useLocation().search
  const redirect = new URLSearchParams(search).get('redirect')

  useEffect(() => {
    if (jobId) {
      setSpycareJobId(jobId)
      setPortal('SpyCare')
      fetch(`${process.env.REACT_APP_API_URL}/data/spycare_job/id/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.text())
        .then(data => {
          const spycareJob = data ? JSON.parse(data) : null
          setJobId(spycareJob ? spycareJob.snapshotJobId.toString() : null)
          if (user.authorities.includes('ROLE_Employee')) {
            setRecAccess(1)
            setInvAccess(1)
            setIbaAccess(1)
          }
          else {
            fetch(`${process.env.REACT_APP_API_URL}/data/job_no_summary/id/${spycareJob.snapshotJobId}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            .then(res => res.json())
            .then(data => {
              setRecAccess(data.recommendationsAccess)
              setInvAccess(data.inventoryAccess)
              setIbaAccess(data.reportEnabled)
            })
          }
        })
    }
  }, [jobId, setJobId, setSpycareJobId, setPortal, setRecAccess, setInvAccess, user, setIbaAccess, token])

  return <Redirect to={redirect ? `/${redirect}` : '/'} />
}

export default HandleJob